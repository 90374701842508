import React from 'react'

import Layout from '../../components/Layout'
import { Link, graphql } from 'gatsby'

export default class GalleryIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow:
                'rgb(50, 115, 220) 0.5rem 0px 0px, rgb(50, 115, 220) -0.5rem 0px 0px',
              backgroundColor: 'rgb(50, 115, 220)',
              color: 'white',
              padding: '1rem',
            }}
          >
            Related Reports
          </h1>
        </div>
        <section className="section">
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h1 className="title is-size-3 is-bold-light">2021</h1>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="https://ncov.dxy.cn/ncovh5/view/en_pneumonia"
                    >
                      <p className="is-size-4">
                      COVID-19 Global Pandemic Real-Time Report
                      </p>
                    </Link>
                  </li>
                </ul>

                <h1 className="title is-size-3 is-bold-light">2020</h1>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="http://wsb.gansu.gov.cn/web/article/4028805f701d8efb01701dc9a6480023.html"
                    >
                      <p className="is-size-4">
                      Overseas Chinese in Canada raise funds to support Gansu's fight against the pandemic - Foreign Affairs Office of the People's Government of Gansu Province
                      </p>
                    </Link>
                  </li>
                </ul>
                <h1 className="title is-size-3 is-bold-light">2018</h1>
                <ul className="taglist">
                  <li>
                    <Link
                      target="_blank"
                      to="http://canada.haiwainet.cn/n/2018/0417/c3542303-31300047.html"
                    >
                      <p className="is-size-4">
                      Canada Gansu Federation of Chamber of Commerce was formally established in Toronto - People's Daily Overseas
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
              </div>
            </div>
        </section>
      </Layout>
    )
  }
}
